import {useEffect, useState, useCallback} from 'react';
import useSWR from 'swr';
import {RPC} from 'shared/api.js';

import {handleError} from './effects.js';

const EMPTY_RESULT = Object.freeze([]);

export function useFetchRefresh({
  method,
  search_query,
  per_page,
  refresh,
  sort,
}) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);

  // Reset pagination state when search query changes
  useEffect(() => {
    setOffset(0);
    setTotal(0);
  }, [search_query]);

  const memo_fetcher = useCallback(() => {
    setLoading(true);

    RPC(method, {
      q: search_query,
      pagination: [offset, per_page],
      sort: sort || {},
    })
      .then((result) => {
        setTotal(result.total);
        setResult(result);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [method, search_query, offset, per_page, sort, setTotal]);

  useEffect(() => {
    memo_fetcher();
  }, [memo_fetcher, refresh]);

  return {
    loading,
    rows: result?.rows || EMPTY_RESULT,
    total,
    offset,
    setOffset,
  };
}

export function useResource(method, id) {
  const {data, error} = useSWR(id, (id) => RPC(method, {id}));

  if (error) handleError(error);

  const resource = data || {};

  return [resource, error];
}

export function useResources(method, id) {
  const {data, error} = useSWR(id ? [id] : null, (id) =>
    RPC(method, {ids: [id]}),
  );

  if (error) handleError(error);

  const resource = data?.rows[0];

  return [resource];
}

export function useTrigger() {
  const [trigger, setTrigger] = useState(0);

  const doTrigger = useCallback(() => {
    setTrigger(Date.now());
  }, [setTrigger]);

  return [trigger, doTrigger];
}
