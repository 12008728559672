export function formatPrice(cents) {
  if (typeof cents === 'string') {
    cents = Number(cents);
  }

  if (!Number.isInteger(cents) || cents < 0) {
    throw new Error('"cents" needs to be a positive integer', {cents});
  }

  const formatter = new Intl.NumberFormat('de', {
    style: 'currency',
    currency: 'EUR',
  });
  return formatter.format(+cents / 100);
}

export function formatPriceNoCurrencySymbol(cents) {
  if (typeof cents === 'string') {
    cents = Number(cents);
  }

  if (!Number.isInteger(cents) || cents < 0) {
    throw new Error('"cents" needs to be a positive integer', {cents});
  }

  const formatter = new Intl.NumberFormat('de', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(+cents / 100);
}
