import {useForm} from 'react-hook-form';
import {BAD_REQUEST, NOT_FOUND, RPC} from 'shared/api.js';
import {setFormErrors} from 'shared/effects.js';
import {useEffect, useState} from 'react';
import IconButton from 'shared/components/IconButton.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {TYPES} from 'shared/constants.js';

import {createCashDeposit} from '../../actions.js';
import {handleError, alert, showDialog, confirmClose} from '../../effects.js';
import {useWizard} from '../../components/Wizard.js';
import {ReactComponent as ArrowLeftSvg} from '../../assets/icon_arrow_left.svg';
import ProgressBar from '../../components/ProgressBar.js';
import Navigation from '../../components/Navigation.js';

import styles from './CreateCashDepositDialog.module.scss';
import TenantData from './TenantData.js';
import RentalData from './RentalData.js';

const {REACT_APP_DEV_MODE} = process.env;

export default function CreateCashDepositDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const rental_contract = {};
  const {
    control,
    register,
    reset,
    watch,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tenant_type: TYPES.individual,
      directors: [{index: 0, first_name: '', last_name: ''}],
    },
  });

  const steps = [
    {id: 'tenant', label: 'Mieter'},
    {id: 'property', label: 'Mieteinheit'},
  ];

  const {
    step,
    next,
    previous,
    index_step: current_step,
    is_last_step,
    is_first_step,
  } = useWizard(steps);

  useEffect(() => {
    if (REACT_APP_DEV_MODE === 'true') {
      RPC('getDevelopmentFormState', {id: 'create-cash-deposit'}).then(
        (res) => {
          reset(res, {keepDefaultValues: true});
        },
      );
    }
  }, [reset]);

  const {title, submit} = pages[step.id] || {};

  const submit_step = handleSubmit((fields) => {
    return submit({fields, next, setError, setVisible});
  });

  return (
    <Dialog
      title={title}
      show={visible}
      backButton={!is_first_step && <BackButton onClick={previous} />}
      onHide={() => confirmClose({isDirty})}
      {...props}
      footer={
        <Navigation
          loading={isSubmitting}
          disabled={!isDirty}
          submit_step={submit_step}
          is_last_step={is_last_step}
          submit_action_title="Kautionskonto erstellen"
        />
      }>
      <div className={styles.container}>
        <InvitationSteps
          {...{
            errors,
            register,
            control,
            watch,
            rental_contract,
            step,
          }}
        />
        {steps.length > 1 && (
          <ProgressBar
            current_step={current_step}
            steps={steps}
            className={styles.progress_bar}
          />
        )}
      </div>
    </Dialog>
  );
}

function InvitationSteps({errors, register, control, watch, step}) {
  return (
    <>
      {step.id === 'tenant' && (
        <TenantData
          errors={errors}
          register={register}
          control={control}
          watch={watch}
        />
      )}
      {step.id === 'property' && (
        <RentalData errors={errors} register={register} control={control} />
      )}
    </>
  );
}

const pages = {
  tenant: {
    title: 'Mieter hinzufügen',
    async submit({fields, setError, next}) {
      try {
        await RPC('validateCashDepositTenantStep', fields);
      } catch (err) {
        showError(err, setError);
        return;
      }

      next();
    },
  },

  property: {
    title: 'Mieteinheit',
    async submit({fields, setError, setVisible}) {
      let viban;
      try {
        ({viban} = await createCashDeposit(fields));
      } catch (err) {
        showError(err, setError);
        return;
      }

      setVisible(false);
      showDialog('cash_deposit_success', {viban});
    },
  },
};

function BackButton({onClick}) {
  return (
    <IconButton onClick={onClick} className={styles.back}>
      <ArrowLeftSvg />
    </IconButton>
  );
}

function showError(err, setError) {
  if (err.code === BAD_REQUEST) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    }
  } else if (err.code === NOT_FOUND) {
    alert({
      title: 'Bitte wenden Sie sich an den Support',
      text: 'Ihr Kautionskonten Kontingent muss aufgestockt werden. Nach einer kurzen Infomail erledigen wir dies gerne für Sie. Ihnen entstehen keine Kosten, lediglich der Support muss informiert werden.',
      email: 'partnersupport@getmomo.de',
    });
  } else {
    handleError(err);
  }
}
