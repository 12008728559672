import {useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';
import {MANAGER_PATHS} from 'shared/constants.js';

import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import {confirm, handleError} from '../../effects.js';
import {deletePropertyOwner} from '../../actions.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useTrigger} from '../../hooks.js';

import PropertyOwnersTable from './PropertyOwnersTable.js';
import PropertyOwnerDialog from './PropertyOwnerDialog.js';
import CreatePropertyOwnerDialog from './CreatePropertyOwnerDialog.js';

export default function PropertyOwnersScreen() {
  const [search_query, setSearchQuery] = useState('');
  const [reset_table_trigger, doResetTable] = useTrigger();

  return (
    <Layout
      button={<ButtonCreatePropertyOwner resetTable={doResetTable} />}
      search={<SearchInput onValue={setSearchQuery} />}>
      <Switch>
        <Route path={MANAGER_PATHS.CreatePropertyOwnerDialog}>
          <CreatePropertyOwnerDialog />
        </Route>
        <Route path={`${MANAGER_PATHS.PropertyOwnersScreen}/:id`}>
          <PropertyOwnerDialog onDeletePropertyOwner={onDeletePropertyOwner} />
        </Route>
      </Switch>
      <PropertyOwnersTable
        search_query={search_query}
        reset_table_trigger={reset_table_trigger}
      />
    </Layout>
  );
}

function ButtonCreatePropertyOwner({resetTable}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Eigentümer erstellen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.CreatePropertyOwnerDialog);
      }}
    />
  );
}

async function onDeletePropertyOwner({property_owner, notify}) {
  const {id} = property_owner;

  if (
    !(await confirm({
      title: `Möchten Sie den Eigentümer wirklich endgültig entfernen?`,
      text: 'Der Vorgang kann nicht Rückgängig gemacht werden, Sie können den Eigentümer aber jederzeit erneut anlegen.',
      proceed_label: 'Jetzt löschen',
    }))
  ) {
    return;
  }

  try {
    await deletePropertyOwner(id);
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Der Vertrag wurde gelöscht.'});
}
