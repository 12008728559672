import {ReactComponent as AddIcon} from 'shared/components/add_circle_primary.svg';
import Button from 'shared/components/Button.js';
import IconButton from 'shared/components/IconButton.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';
import {Controller} from 'react-hook-form';
import DateInput from 'shared/components/DateInput.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';

import {ReactComponent as TrashSvg} from '../../assets/trash.svg';

import styles from './CashDepositDialog.module.scss';
import tenant_data_styles from './TenantData.module.scss';

export default function IndividualTenantData({
  errors,
  control,
  register,
  watch,
  setValue,
}) {
  const tenant_2_exists = watch('tenant_2_exists');

  function AddSecondTenantButton() {
    return (
      <div>
        <Button
          onClick={() => {
            setValue('tenant_2_exists', true, {shouldDirty: true});
          }}
          title="Zweiten Mieter hinzufügen"
          className={tenant_data_styles.add_person_button}
          Icon={AddIcon}
        />
      </div>
    );
  }

  return (
    <>
      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        {tenant_2_exists ? 'Mieter 1' : 'Mieter'}
      </div>
      <div className={styles.row}>
        <Input
          label="Vorname"
          {...register('tenant_1_first_name')}
          error={errors.tenant_1_first_name?.message}
        />
        <Input
          label="Nachname"
          {...register('tenant_1_last_name')}
          error={errors.tenant_1_last_name?.message}
        />
      </div>
      <Controller
        name={'tenant_1_date_of_birth'}
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label={'Geburtsdatum (optional)'}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
      {tenant_2_exists ? (
        <>
          <SeparatorLine />
          <div className={classNames(styles.row, tenant_data_styles.header)}>
            <div className={text_styles.body1_bold_left}>Mieter 2</div>
            <IconButton
              onClick={() => {
                setValue('tenant_2_exists', false, {shouldDirty: true});
              }}>
              <TrashSvg />
            </IconButton>
          </div>
          <div>
            <div className={styles.row}>
              <Input
                label="Vorname"
                error={errors.tenant_2_first_name?.message}
                className={styles.margin_right}
                {...register('tenant_2_first_name')}
              />
              <Input
                label="Nachname"
                error={errors.tenant_2_last_name?.message}
                {...register('tenant_2_last_name')}
              />
            </div>

            <Controller
              name={'tenant_2_date_of_birth'}
              control={control}
              render={({field: {value, onChange, name}}) => (
                <DateInput
                  value={value}
                  onChange={onChange}
                  label={'Geburtsdatum (optional)'}
                  error={errors[name]?.message}
                  name={name}
                />
              )}
            />
          </div>
        </>
      ) : (
        <AddSecondTenantButton />
      )}
    </>
  );
}
