import {RPC} from 'shared/api.js';
import {format} from 'date-fns';
import {MANAGER_PATHS} from 'shared/constants.js';

import store from './store/store.js';
import {confirm, reportError} from './effects.js';
import TermsModal from './components/TermsModal/TermsModal.js';

export async function login({
  email_address,
  password,
  history,
  deposit_id,
  token,
}) {
  const {account} = await RPC('login', {email_address, password});

  handleAccount(account);

  store.dispatch({
    type: 'LOGGED_IN',
    payload: {
      account,
    },
  });

  let navigate_to = MANAGER_PATHS.DepositsScreen;
  if (deposit_id) {
    navigate_to = `${MANAGER_PATHS.DepositsScreen}/${deposit_id}`;
  }
  history.push(navigate_to, {token: token || undefined});

  if (account.is_surrogate) return;
  if (!account.missing_consents || account.missing_consents.length === 0) {
    return;
  }

  const was_modal_shown_today =
    account.terms_modal_last_shown_at === format(new Date(), 'yyyy-MM-dd');

  if (!was_modal_shown_today && account.missing_consents.length > 0) {
    const confirmed = await confirm({
      title: 'Wir haben unsere Geschäftsbedingungen aktualisiert.',
      text: <TermsModal consents={account.missing_consents} />,
      proceed_label: 'Änderungen akzeptieren',
      abort_label: 'Überspringen',
    });

    confirmed
      ? await RPC('agreeToTerms', {
          consents: account.missing_consents,
        })
      : await RPC('updateTermsModalLastShownAt');
  }
}

export async function bootstrap() {
  const {account} = await RPC('bootstrap');

  account && handleAccount(account);

  store.dispatch({
    type: 'BOOTSTRAPPED',
    payload: account,
  });
}

export async function updateRentalContract(params) {
  const payload = await RPC('updateRentalContract', params);

  store.dispatch({
    type: 'RENTAL_CONTRACT_UPDATED',
  });
  return payload;
}

export async function updateCashDeposit(params) {
  const payload = await RPC('updateCashDeposit', params);

  store.dispatch({
    type: 'RENTAL_CONTRACT_UPDATED',
  });
  return payload;
}

export async function initiateCreditTransfer(params) {
  await RPC('initiateCreditTransfer', params);

  store.dispatch({
    type: 'RENTAL_CONTRACT_UPDATED',
  });
}

export async function requestCloseCashDeposit(params) {
  await RPC('requestCloseCashDeposit', params);

  store.dispatch({
    type: 'RENTAL_CONTRACT_UPDATED',
  });
}

export async function inviteTenant(params) {
  const payload = await RPC('inviteTenant', params);

  store.dispatch({
    type: 'TENANT_INVITED',
  });
  return payload;
}

export async function deleteDepositGuarantee(deposit_id) {
  await RPC('deleteDepositGuarantee', {deposit_id});

  store.dispatch({
    type: 'RENTAL_CONTRACT_DELETED',
  });
}

export async function releaseDepositGuarantee(deposit_id) {
  await RPC('releaseDepositGuarantee', {deposit_id});

  store.dispatch({
    type: 'RENTAL_CONTRACT_UPDATED',
  });
}

export async function createClaim(params) {
  await RPC('createClaim', params);

  store.dispatch({
    type: 'RENTAL_CONTRACT_CLAIM_CREATED',
  });
}

export async function createPropertyOwner(params) {
  const payload = await RPC('createPropertyOwner', params);

  store.dispatch({
    type: 'PROPERTY_OWNER_ADDED',
  });

  return payload;
}

export async function updatePropertyOwner(params) {
  const payload = await RPC('updatePropertyOwner', params);

  store.dispatch({
    type: 'PROPERTY_OWNER_UPDATED',
  });
  return payload;
}

export async function updateBankingPropertyOwner(params) {
  const payload = await RPC('updateBankingPropertyOwner', params);

  store.dispatch({
    type: 'PROPERTY_OWNER_UPDATED',
  });
  return payload;
}

export async function deletePropertyOwner(id) {
  const payload = {id};
  await RPC('deletePropertyOwner', payload);

  store.dispatch({
    type: 'PROPERTY_OWNER_DELETED',
  });
}

export async function logout(history) {
  window.$chatwoot?.reset?.();

  try {
    await RPC('logout');
  } catch (err) {
    reportError(err);
  }
  store.dispatch({
    type: 'RESET',
  });
  // Ensure that LOGGED_OUT remains after RESET
  store.dispatch({
    type: 'LOGGED_OUT',
  });
  history.push({
    pathname: '/',
    state: {logout: true},
  });
}

function handleAccount(account) {
  const {
    manager_id,
    email_address,
    first_name,
    last_name,
    company_name,
    region,
  } = account;

  window.$chatwoot?.setUser?.(manager_id, {
    email: email_address,
    name: `${first_name} ${last_name}`,
    company_name,
    city: region,
    country: 'DE',
  });
}

export async function createCashDeposit(params) {
  const payload = await RPC('createCashDeposit', params);

  store.dispatch({
    type: 'CASH_DEPOSIT_CREATED',
  });
  return payload;
}
