import {ReactComponent as AddIcon} from 'shared/components/add_circle_primary.svg';
import Button from 'shared/components/Button.js';
import IconButton from 'shared/components/IconButton.js';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';
import Input from 'shared/components/Input.js';
import {Controller, useFieldArray} from 'react-hook-form';
import DateInput from 'shared/components/DateInput.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';

import {ReactComponent as TrashSvg} from '../../assets/trash.svg';

import styles from './CashDepositDialog.module.scss';
import tenant_data_styles from './TenantData.module.scss';

export default function CompanyTenantData({errors, control, register}) {
  const maxLength = 3;
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'directors',
    rules: {maxLength},
  });

  function AddPersonButton({index}) {
    return (
      <div>
        <Button
          onClick={() => {
            append({first_name: '', last_name: '', date_of_birth: null, index});
          }}
          title={'Weiteren Geschäftsführer hinzufügen'}
          className={tenant_data_styles.add_person_button}
          Icon={AddIcon}
        />
      </div>
    );
  }

  return (
    <>
      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Mieter
      </div>

      <Input
        label={'Name und Rechtsform'}
        {...register('tenant_company_name')}
        error={errors.tenant_company_name?.message}
      />

      <SeparatorLine />

      {fields.map((field, index) => (
        <div key={field.id}>
          <div className={classNames(styles.row, tenant_data_styles.header)}>
            <div className={text_styles.body1_bold_left}>
              Geschäftsführer {index + 1}
            </div>

            {index > 0 && fields.length === index + 1 && (
              <IconButton
                onClick={() => {
                  remove(index);
                }}>
                <TrashSvg />
              </IconButton>
            )}
          </div>

          <div className={styles.row}>
            <Input
              label="Vorname"
              {...register(`directors.${index}.first_name`)}
              error={errors.directors?.[index]?.first_name?.message}
            />
            <Input
              label="Nachname"
              {...register(`directors.${index}.last_name`)}
              error={errors.directors?.[index]?.last_name?.message}
            />
          </div>
          <Controller
            name={`directors.${index}.date_of_birth`}
            control={control}
            render={({field: {value, onChange, name}}) => (
              <DateInput
                value={value}
                onChange={onChange}
                label={'Geburtsdatum (optional)'}
                error={errors[name]?.message}
                name={name}
              />
            )}
          />

          {fields.length === index + 1 && fields.length < maxLength && (
            <AddPersonButton index={index + 1} />
          )}

          {fields.length !== index + 1 && <SeparatorLine />}
        </div>
      ))}
    </>
  );
}
