import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import classNames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Icon from 'shared/components/Icon.js';
import {MANAGER_PATHS} from 'shared/constants.js';
import {useState, useEffect} from 'react';

import UserAccount from './UserAccount.js';
import SidebarItem from './SidebarItem.js';
import styles from './Sidebar.module.scss';
import {ReactComponent as MomoLogo} from './momo_logo.svg';
import {ReactComponent as MomoLogoSmall} from './momo_logo_small.svg';
import {ReactComponent as MomoDepositSvg} from './momo_deposit.svg';
import {ReactComponent as MomoOwnerSvg} from './momo_owner.svg';
import {ReactComponent as ExpandSvg} from './icon_expand.svg';
import {ReactComponent as CollapseSvg} from './icon_collapse.svg';
import CustomerFeedback from './CustomerFeedback.js';
import Integrations from './Integrations.js';

const MINIMIZED_LOCAL_STORAGE_KEY = 'momo_manager_main-menu-minimized';

export default function Sidebar() {
  const {features, ...account} = useSelector(
    (state) => state.user.account || {features: {}},
  );

  const [minimized, setMinimized] = useState(
    (localStorage.getItem(MINIMIZED_LOCAL_STORAGE_KEY) ?? '0') === '1',
  );
  useEffect(() => {
    localStorage.setItem(MINIMIZED_LOCAL_STORAGE_KEY, minimized ? '1' : '0');
  }, [minimized]);

  return (
    <div
      className={classNames(
        styles.sidebar,
        minimized && styles.sidebar_collapsed,
      )}>
      <div>
        <Link to="/">
          <div className={styles.logo}>
            {minimized ? (
              <MomoLogoSmall alt="logo home" />
            ) : (
              <MomoLogo alt="logo home" />
            )}
          </div>
        </Link>
        <SidebarItem
          name="Kautionsverwaltung"
          to={MANAGER_PATHS.DepositsScreen}
          minimized={minimized}>
          <MomoDepositSvg />
        </SidebarItem>

        <SeparatorLine className={styles.line} />

        <SidebarItem
          name="Eigentümer"
          to={MANAGER_PATHS.PropertyOwnersScreen}
          className={styles.item}
          minimized={minimized}>
          <MomoOwnerSvg />
        </SidebarItem>

        <SeparatorLine className={styles.line} />

        {minimized ? (
          <ExpandMenuButton onClick={() => setMinimized(false)} />
        ) : (
          <CollapseMenuButton onClick={() => setMinimized(true)} />
        )}
      </div>
      <div>
        <Integrations minimized={minimized} />
        <CustomerFeedback minimized={minimized} />
        {account.email_address && <UserAccount minimized={minimized} />}
      </div>
    </div>
  );
}

function CollapseMenuButton({onClick}) {
  return (
    <button className={styles.menu} onClick={onClick}>
      <Icon className={styles.collapse_icon}>
        <CollapseSvg />
      </Icon>
      <div className={text_styles.body2}>Menü einklappen</div>
    </button>
  );
}

function ExpandMenuButton({onClick}) {
  return (
    <button className={styles.menu} onClick={onClick}>
      <OverlayTrigger
        key="right"
        placement="right"
        overlay={<Tooltip>Menü ausklappen</Tooltip>}>
        <Icon className={styles.expand_icon}>
          <ExpandSvg />
        </Icon>
      </OverlayTrigger>
    </button>
  );
}
