import Input from 'shared/components/Input.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import {Controller, useFieldArray} from 'react-hook-form';
import {TYPES} from 'shared/constants.js';
import Button from 'shared/components/Button.js';
import IconButton from 'shared/components/IconButton.js';
import {ReactComponent as AddIcon} from 'shared/components/add_circle_primary.svg';
import classNames from 'classnames';
import text_styles from 'shared/styles/text_styles.module.scss';

import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import RadioToggle from '../../components/RadioToggle.js';
import tenant_data_styles from '../deposits/TenantData.module.scss';

import styles from './TenantData.module.scss';

export default function TenantData({errors, register, control, watch}) {
  const maxLength = 3;
  const {fields, append, remove} = useFieldArray({
    control,
    name: 'directors',
    rules: {maxLength},
  });

  function AddPersonButton({index}) {
    return (
      <div>
        <Button
          onClick={() => {
            append({first_name: '', last_name: '', date_of_birth: null, index});
          }}
          title={'Weiteren Geschäftsführer hinzufügen'}
          className={tenant_data_styles.add_person_button}
          Icon={AddIcon}
        />
      </div>
    );
  }

  return (
    <>
      <div className={styles.row}>
        <Controller
          control={control}
          name="tenant_type"
          render={({field: {onChange, name}}) => (
            <RadioToggle
              name={name}
              option_1="individual"
              option_1_label="Privatperson"
              option_2="company"
              option_2_label="Firma"
              onChange={onChange}
              watch={watch}
            />
          )}
        />
      </div>

      {watch('tenant_type') === TYPES.individual && (
        <>
          <div className={styles.row}>
            <Input
              label={'Vorname Mieter 1'}
              error={errors.tenant_1_first_name?.message}
              {...register('tenant_1_first_name')}
            />
            <Input
              label={'Nachname Mieter 1'}
              error={errors.tenant_1_last_name?.message}
              {...register('tenant_1_last_name')}
            />
          </div>

          <div className={styles.row}>
            <Input
              label={'Vorname Mieter 2 (optional)'}
              error={errors.tenant_2_first_name?.message}
              {...register('tenant_2_first_name')}
            />
            <Input
              label={'Nachname Mieter 2 (optional)'}
              error={errors.tenant_2_last_name?.message}
              {...register('tenant_2_last_name')}
            />
          </div>
        </>
      )}

      {watch('tenant_type') === TYPES.company && (
        <>
          <Input
            label="Name und Rechtsform"
            error={errors.company_name?.message}
            placeholder="z.B. Feinkost Schmitt GmbH"
            {...register('company_name')}
          />

          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                {fields.length > 1 && (
                  <div
                    className={classNames(
                      styles.row,
                      tenant_data_styles.header,
                    )}>
                    <div className={text_styles.body1_bold_left}>
                      Geschäftsführer {index + 1}
                    </div>

                    {index > 0 && fields.length === index + 1 && (
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}>
                        <TrashSvg />
                      </IconButton>
                    )}
                  </div>
                )}

                <div className={styles.row}>
                  <Input
                    label={
                      fields.length === 1
                        ? 'Vorname Geschäftsführer'
                        : 'Vorname'
                    }
                    {...register(`directors.${index}.first_name`)}
                    error={errors.directors?.[index]?.first_name?.message}
                  />
                  <Input
                    label={
                      fields.length === 1
                        ? 'Nachname Geschäftsführer'
                        : 'Nachname'
                    }
                    {...register(`directors.${index}.last_name`)}
                    error={errors.directors?.[index]?.last_name?.message}
                  />
                </div>

                {fields.length === index + 1 && fields.length < maxLength && (
                  <AddPersonButton index={index + 1} />
                )}

                {fields.length !== index + 1 && <SeparatorLine />}
              </div>
            );
          })}
        </>
      )}

      <SeparatorLine className={styles.separator} />

      <Input
        type="text"
        label="Beschreibung (optional)"
        placeholder="z. B. ID-123"
        error={errors.given_reference?.message}
        {...register('given_reference')}
        className={styles.given_reference}
      />
    </>
  );
}
