import React from 'react';
import {useForm, Controller} from 'react-hook-form';
import classNames from 'classnames';
import {formatPrice, formatShortId} from 'shared/utils.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import PriceInput from 'shared/components/PriceInput.js';
import {BAD_REQUEST} from 'shared/api.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import TextArea from 'shared/components/TextArea.js';
import Input from 'shared/components/Input';
import SeparatorLine from 'shared/components/SeparatorLine';
import {VALIDATION_ERROR} from 'shared/constants.js';

import Dialog from '../../components/dialog/Dialog.js';
import {createClaim} from '../../actions.js';
import {handleError, alert, closeDialog} from '../../effects.js';

import styles from './ClaimDialog.module.scss';

export default function ClaimDialog({deposit, ...props}) {
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({mode: 'onChange'});

  if (!deposit) return null;

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, deposit, setError}),
  );

  const footer = (
    <div className={styles.container}>
      <div className={styles.row}>
        <p className={text_styles.body1_bold_left}>Kaution:</p>
        <div className={styles.chip}>
          <p className={text_styles.typeface_bright_bold}>
            {formatPrice(deposit.deposit_amount_cents)}
          </p>
        </div>
        <p className={classNames(text_styles.body1_bold_left, styles.status)}>
          Status:
        </p>
        <p className={text_styles.body2}>{deposit.status}</p>
      </div>
      <Button
        type="submit"
        title="Betrag anfordern"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  return (
    <Dialog
      title="Kaution einfordern"
      footer={footer}
      onHide={() => closeDialog('claim')}
      {...props}>
      <div className={classNames(styles.row, styles.first_row)}>
        <div className={styles.tenant_label}>
          <div className={text_styles.body1_left}>Mieter:</div>
        </div>

        <div className={styles.tenant}>
          <div className={classNames(text_styles.body1_bold_left, styles.name)}>
            {deposit.tenant_first_name} {deposit.tenant_last_name}
          </div>
          <div className={text_styles.body1_left}>
            {`${deposit.postal_code} ${deposit.region}, ${deposit.street_name} ${deposit.street_number}`}
          </div>
        </div>

        <div className={styles.rental_ids}>
          <div
            className={classNames(text_styles.caption_right, styles.reference)}>
            {deposit.given_reference
              ? `Interne Nr.: ${deposit.given_reference}`
              : ' '}
          </div>
          <div>
            <div className={text_styles.caption_right}>
              {`Momo-ID: ${formatShortId(deposit.short_id)}`}
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.row, styles.input_row)}>
        <div className={classNames(text_styles.body1_left, styles.label)}>
          Einfordern:
        </div>
        <Controller
          control={control}
          name="amount_cents"
          render={({field: {onChange, value, name}}) => (
            <PriceInput
              className={classNames(styles.downlift)}
              value={value}
              onChange={onChange}
              error={errors[name]?.message}
              suffix=" €"
            />
          )}
        />
      </div>

      <TextArea
        label="Schilderung"
        placeholder="Bitte tragen Sie hier eine Schilderung zum Vorgang ein …"
        maxLength="500"
        error={errors.comment?.message}
        {...register('comment')}
      />

      <SeparatorLine className={styles.line} />

      <Input
        label="Empfänger (Name oder Firma)"
        placeholder="z.B. XYZ Immobilien AG"
        error={errors.bank_account_name?.message}
        {...register('bank_account_name')}
      />

      <Input
        label="Empfänger IBAN"
        placeholder="z. B. DE45 123…"
        error={errors.bank_account_iban?.message}
        {...register('bank_account_iban')}
      />

      <SeparatorLine className={styles.line} />

      <Controller
        control={control}
        name="agree"
        defaultValue={false}
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            error={errors[name]?.message}
            value={value}
            onChange={onChange}
            label="Hiermit bestätigen wir die vertraglichen Rahmenbedingungen und AGBs"
            className={styles.last_row}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({fields, deposit, setError}) {
  fields.rental_contract_id = deposit.rental_contract_id;

  try {
    await createClaim(fields);
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      } else if (
        err.message ===
        VALIDATION_ERROR.claim_amount_exceeds_rental_contract_deposit
      ) {
        alert({
          title: 'Die Summe überschreitet das hinterlegte Kontingent.',
          text: 'Bitte reduzieren Sie den Betrag auf die verbleibende Summe bzw. darf diese die Höhe der festgelegten Kaution nicht überschreiten.',
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  closeDialog('claim');

  alert({
    title: 'Der Betrag ist angefordert',
    text: 'Wir überprüfen Ihre Kautionsforderung und überweisen den Betrag innerhalb von 2 Werktagen auf Ihre hinterlegte Bankverbindung. Sollten Rückfragen aufkommen, melden wir uns schnellstmöglich bei Ihnen.',
  });
}
