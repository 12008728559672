import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useLocation} from 'react-router-dom/cjs/react-router-dom.min.js';
import {RPC} from 'shared/api.js';
import {formatPrice} from 'shared/utils.js';
import {MANAGER_PATHS} from 'shared/constants.js';

import {alert, handleError} from '../../effects.js';
import Layout from '../../components/layout/Layout.js';
import ButtonAdd from '../../components/button_add/ButtonAdd.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import {useTrigger} from '../../hooks.js';
import TenantInvitationDialog from '../inviteTenant/TenantInvitationDialog.js';
import CreateRentalContractDialog from '../../components/CreateRentalContractDialog.js';
import CreateCashDepositDialog from '../createCashDeposit/CreateCashDepositDialog.js';

import DepositsTable from './DepositsTable.js';
import DepositGuaranteeDialog from './DepositGuaranteeDialog.js';
import CashDepositDialog from './CashDepositDialog.js';
import InitiateCreditTransferDialog from './InitiateCreditTransferDialog.js';
import CashDepositTransactionsDialog from './CashDepositTransactionsDialog.js';

export default function DepositsScreen() {
  const history = useHistory();
  const {features} = useSelector((state) => state.user.account);
  const [search_query, setSearchQuery] = useState('');
  const [reset_table_trigger, doResetTable] = useTrigger();

  useAcceptRentalContract();

  function routeToListScreen() {
    history.push(MANAGER_PATHS.DepositsScreen);
  }

  return (
    <>
      <Layout
        button={
          <InvitationButton features={features} resetTable={doResetTable} />
        }
        search={<SearchInput onValue={setSearchQuery} />}>
        <Switch>
          <Route path={MANAGER_PATHS.CreateRentalContractDialog}>
            <CreateRentalContractDialog onExited={routeToListScreen} />
          </Route>
          <Route path={MANAGER_PATHS.TenantInvitationDialog}>
            <TenantInvitationDialog onExited={routeToListScreen} />
          </Route>
          <Route path={MANAGER_PATHS.CreateCashDepositDialog}>
            <CreateCashDepositDialog onExited={routeToListScreen} />
          </Route>
          <Route
            path={`${MANAGER_PATHS.InitiateCreditTransferDialog}/:deposit_id`}>
            <InitiateCreditTransferDialog
              onExited={routeToListScreen}
              idempotency_timestamp={new Date().toISOString()}
            />
          </Route>
          <Route
            path={`${MANAGER_PATHS.CashDepositTransactionsDialog}/:deposit_id`}>
            <CashDepositTransactionsDialog onExited={routeToListScreen} />
          </Route>
          <Route path={`${MANAGER_PATHS.DepositGuaranteeDialog}/:deposit_id`}>
            <DepositGuaranteeDialog onExited={routeToListScreen} />
          </Route>
          <Route path={`${MANAGER_PATHS.CashDepositDialog}/:deposit_id`}>
            <CashDepositDialog onExited={routeToListScreen} />
          </Route>
          <Route path={`${MANAGER_PATHS.DepositsScreen}/:deposit_id`}>
            {/* This route is here for backwards compatibility https://github.com/get-momo/code/pull/1719 */}
            <DepositGuaranteeDialog onExited={routeToListScreen} />
          </Route>
        </Switch>
        <DepositsTable
          search_query={search_query}
          reset_table_trigger={reset_table_trigger}
        />
      </Layout>
    </>
  );
}

function ButtonDepositGuaranteeInvitation({resetTable}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Mieter einladen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.TenantInvitationDialog);
      }}
      name="tenant_invitation"
    />
  );
}

function ButtonCashDepositInvitation({resetTable}) {
  const history = useHistory();

  return (
    <ButtonAdd
      title="Mieter hinzufügen"
      onClick={() => {
        resetTable();
        history.push(MANAGER_PATHS.CreateCashDepositDialog);
      }}
      name="cash_deposit"
    />
  );
}

function InvitationButton({features, resetTable}) {
  return features?.cash_deposit ? (
    <ButtonCashDepositInvitation resetTable={resetTable} />
  ) : (
    <ButtonDepositGuaranteeInvitation resetTable={resetTable} />
  );
}

function useAcceptRentalContract() {
  const history = useHistory();
  const dispatch = useDispatch();

  const {search, pathname, state} = useLocation();
  const [initial_pathname] = useState(pathname);

  const search_token = search.slice(1);
  const {token: state_token} = state ?? {};

  const token = search_token || state_token;

  useEffect(() => {
    if (!token) return;

    function unsetToken() {
      history.replace(MANAGER_PATHS.DepositsScreen, {
        token: undefined,
      });
    }

    RPC('acceptRentalContract', {token})
      .then(async ({valid, ...data}) => {
        if (!valid) {
          history.replace(initial_pathname, {token: undefined});
          return;
        }
        unsetToken();
        dispatch({type: 'RENTAL_CONTRACT_ADDED'});

        await alert({
          title: `Kautionsanfrage ${formatPrice(data.deposit_amount_cents)}`,
          // eslint-disable-next-line no-irregular-whitespace
          text: `Ihr Mieter ${data.tenant_display_name} hat eine Getmomo Kautionsbürgschaft für ${data.street_name} ${data.street_number}, ${data.postal_code} ${data.region} beantragt.\n\nFür diesen Mieter wurde ein Eintrag in Ihrer Kautionsverwaltungstabelle hinzugefügt.`,
          label: 'Eintrag überprüfen',
          removeBackdrop: true,
        });
        history.push(`${MANAGER_PATHS.DepositsScreen}/${data.deposit_id}`);
      })
      .catch((...args) => {
        unsetToken();
        return handleError(args);
      });
  }, [initial_pathname, token, history, dispatch]);
}
